
.Info {

}
.OrganizationsHeader {
  display: flex;
  justify-content: space-between;
}
.boldText {
  font-weight: 700;
}
.editOrg {
  display: flex;
  color: rgb(96 165 250);
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.editWord {
  margin-right: 0.25rem;
}
.editRow {
  margin-bottom: 1rem;
}
.orgLogo {
  margin-right: 1rem !important;
}
.adminsHeader {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.adminItem {
  background-color: #F8F8F8;
  height: 3rem;
  padding-left: 1rem;
  border-radius: 4px;
}
.organizationInfo {
  height: calc(100vh - 250px);
}
.CancelButton {
  padding-right: 1rem;
}