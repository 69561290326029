@import "../../Application/Variables";
.FullOverlay {
  display: flex;
  flex-direction: column;
  flex: auto;
  > div {

    display: flex;
    flex-direction: column;
    flex: auto;
  }
}