@import '../../Application/Variables';


.CardBody {
  width: 328px;
  height: 372px;
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .CardTitle {
    margin-bottom: 24px;
    color:inherit;
  }

  .Icon {
    font-size: 132.5px;
  }

  &.success {
    color: @success-color;
    background-color: @success-color-light;
  }

  &.warning {
    color: @warning-color;
    background-color: @warning-color-light;
  }

  &.error {
    color: @error-color;
    background-color: @error-color-light;
  }

  &.waiting {
    color: @disabled-color;
    background-color: @disabled-color-light;
  }
}

