.form {
  padding: 24px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
  background-color: white;
  border-radius: 6px;
  margin: 15px 15px 100px 15px;
}

.header {
  margin-bottom: 24px;
}

.footer {
  text-align: center;
}