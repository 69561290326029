
@import "../../Application/Variables";


.PageHeader {
  border-bottom: @border-width-base @border-color-base @border-style-base;
}

.LoginPopover {

  &.@{ant-prefix}-popover{
    &-inner-content {
      padding: 0;
      background: red;
    }
  }
}