.OrganizationItemAvatar {
    padding-right: 1rem;
}
.InivationStatus {
    padding-top: 1px;
    padding-left: 1rem;
}
.OrgRow {
    border: 1px solid #D9DEEC;
    border-radius: 6px;
    // box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    padding: 10px;
}
.UserInfo {
    height: calc(100vh - 250px);
}