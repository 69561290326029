
.licensesSpace {
    width: 100%;
    background-color: #fff !important;
}
.addIcon {
    padding-right: 0.5rem;
    font-style: normal;
}
.licenseCollapse .licenseCollapsePanel,
.licenseCollapse .licenseCollapsePanel {
    margin-bottom: 10px;
    overflow: hidden;
    background: #fff;
    border: 1px solid #D9DEEC !important;
    border-radius: 6px !important;
}
.panelContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    background-color: #F8F8F8;
    height: 3.5rem;
    padding-left: 1rem;
    margin-bottom: 10px;
    box-shadow: 0px 1px 0px #D9DEEC;
}
.addUserPopup {
    width: 300px;
}
.addUserIcon {
    padding-right: 0.25rem;
    font-size: 1.5rem;
}

.extraAddUser {
    line-height: 56px;
    display: inline;
    vertical-align: middle;
}

.licenseList {
    height: calc(100vh - 230px);
}