@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import './Variables.less';

.@{ant-prefix}-menu {
  &:not(&-horizontal) &-item-selected {
    background-color: @menu-item-active-bg;
    border-left: 3px solid @menu-highlight-color;
  }
  &-item,
  &-submenu-title {
    .@{ant-prefix}-item-icon,
    .@{iconfont-css-prefix} {
      vertical-align: middle;
    }
  }

  &-inline,
  &-vertical,
  &-vertical-left {
    border-right: none;
  }
}

// List

body{
  font-family: Roboto, Arial, Avenir, SansSerif, serif;
}

.@{ant-prefix}-form-item-label{
  font-size: 16px;
  color: @text-color;
}

.@{ant-prefix}-popover-arrow-content{
  display: none !important;
}
