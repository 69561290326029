.FloatingActionButton {
  position: absolute;
  top: 80px;
  border-radius: 42px;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  background: #FFF;
  text-align: center;
  z-index: 1;

  &-sm {
    width: 14px;
    height: 14px;
    line-height: 14px;
  }

  &-md {
    width: 28px;
    height: 28px;
    line-height: 24px;
  }

  &-lg {
    width: 36px;
    height: 36px;
    line-height: 36px;
  }

  &-xl {
    width: 42px;
    height: 42px;
    line-height: 42px;
  }
}