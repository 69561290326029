
.OrganizationUsers {
  height: 100%;
}

.UserList {
  height: calc(100vh - 280px);
  overflow-x: auto;
}

.UserItem {
  border-style: solid;
  border-color: #D9DEEC;
  border-width: 1px;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
}

.AlignMiddle {
  height: 32px;
  display: table-cell;
  vertical-align: middle;
}

.Pagination {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 26px;
}